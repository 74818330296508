import ChatBot from "react-simple-chatbot";
import Navbar from "./Navbar";
import "./App.css";
import AuthContext from "./AuthContext";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
function Chat() {
  const { login, user } = useContext(AuthContext);
  const navigate = useNavigate();
  useEffect(() => {
    if (login == false) {
      navigate("/login");
    }
  }, [login]);
  const steps = [
    {
      id: "1",
      message: `Hi ${user}`,
      trigger: "7",
    },
    {
      id: "7",
      message: "Welcome to Chatbot",
      trigger: "8",
    },
    {
      id: "8",
      message: "How can I help you?",
      trigger: "2",
    },
    {
      id: "2",
      options: [
        { value: 1, label: "Apply for Leave Application", trigger: "4" },
        { value: 2, label: "Other query", trigger: "3" },
      ],
    },
    {
      id: "3",
      message: "Okay I will connect with HR team.",
      end: true,
    },
    {
      id: "4",
      message: "How many days of leave do you want?",
      trigger: "5",
    },
    {
      id: "5",
      user: true,
      trigger: "6",
    },
    {
      id: "6",
      message: "Okay {previousValue} days of leave applied.",
      end: true,
    },
  ];
  const handleEnd = ({ steps, values }) => {
    console.log(steps);
    const value = values[0];
    const leaveNumber = steps[5]?.value;
    if (value == 1) {
      axios
        .post("http://localhost:5000/leave", {
          days: leaveNumber,
        })
        .then((response) => {
          if (response.status == 404) {
            alert("Invalid Credentials");
          } else {
            alert("Leave Application Submitted");
          }
        })
        .catch((error) => {
          console.log(error);
        });
      console.log("Leave Application", value);
    } else {
      console.log("Other query");
    }
  };
  return (
    <>
      <Navbar />
      <div className="App">
        <ChatBot steps={steps} handleEnd={handleEnd} />
      </div>
    </>
  );
}

export default Chat;
