import React from "react";
import { Link, useNavigate } from "react-router-dom";
import AuthContext from "./AuthContext";
import "./Login.css";
import Navbar from "./Navbar";
import axios from "axios";


export const Login = () => {
  const { login, setLogin, setUser } = React.useContext(AuthContext);
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const navigate = useNavigate();
  React.useEffect(() => {
  }, [login]);
  const handleSubmit = async () => {
    const response = await axios.post("http://localhost:5000/login", {
      username: username,
      password: password,
    });
    console.log(response)
    console.log(username)
    if(response.status==400)
    {
      alert("Invalid Username or Password");
    }
    console.log(response.status)
    if (response.status == 200) {
      setUser(response.data.username);
      setLogin(true);
    } else {
      setLogin(false);
    }
  };

  var handleSubmits=()=>
  {
    console.log("clicked")
    setLogin(false)
    setUser(null)
  }
  return (
    <>
      <Navbar />
      <div className="login-container">
        <h3>Login</h3>
        <div className="inputs">
          <input
            type="text"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <button type="submit" onClick={handleSubmit}>Submit</button>
        {/* <Link to="/" className="btn btn-primary">Submit</Link> */}
      </div>
    </>
  );
};
