import React from "react";
import "./Navbar.css";

const Navbar = () => {
  return (
    <div className="container">
      <div className="left">
        <div className="logo">Ivanti</div>
      </div>
    </div>
  );
};

export default Navbar;
