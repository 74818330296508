import React, { createContext, useState } from "react";
import Chat from "./Chat";

import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import { Login } from "./Login";
import AuthContext from "./AuthContext";
const router = createBrowserRouter([
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/",
    element: <Chat />,
  },
]);

const App = () => {
  const [login, setLogin] = useState(false);
  const [user, setUser] = useState(null);
  return (
    <AuthContext.Provider value={{ login, setLogin, user, setUser }}>
      <RouterProvider router={router} />;
    </AuthContext.Provider>
  );
};
export default App;
